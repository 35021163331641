<template>
	<v-sheet flat>
		<mw-edit :id="id" storeModule="projects" :model="model" title-field="internal_name">
			<template v-slot:start="slotProps">
				<v-row>
					<v-col>
						<mw-date-picker
							label="Project Start"
							:value="slotProps.data.start"
							@input="(v) => update('start', v)"
							format="DD/MM/YYYY"
							picker-type="date"
						></mw-date-picker>
					</v-col>
					<v-col>
						<mw-date-picker
							label="Project End"
							:value="slotProps.data.end"
							@input="(v) => update('end', v)"
							format="DD/MM/YYYY"
							picker-type="date"
						></mw-date-picker>						

					</v-col>
				</v-row>
			</template>
			<template v-slot:clients="slotProps">
				<mw-select-users
					role="client"
					add-new-button
					:value="slotProps.data.clients"
					@input="(v) => update('clients', v)"
				></mw-select-users>
			</template>
			<template v-slot:bulletin_recipients="slotProps">
				<mw-select-users
					label="Bulletin Recipients"
					role="client"
					add-new-button
					:value="slotProps.data.bulletin_recipients"
					:include="slotProps.data.clients"
					@input="(v) => update('bulletin_recipients', v)"
				></mw-select-users>
			</template>
			<template v-slot:project_managers="slotProps">
				<mw-select-users
					role="project_manager"
					add-new-button
					:value="slotProps.data.project_managers"
					@input="(v) => update('project_managers', v)"
				></mw-select-users>
			</template>
			<template v-slot:screeners="slotProps">
				<mw-select
					v-bind="model.screeners.options"
					multiple
					label="Screeners"
					:include="screeners"
					add-new-button
					:value="slotProps.data.screeners"
					@input="(v) => update('screeners', v)"
				></mw-select>
			</template>
			<template v-slot:consent="slotProps">
				<mw-select
					v-bind="model.consent.options"
					label="Consent"
					:include="consents"
					add-new-button
					:value="slotProps.data.consent"
					@input="(v) => update('consent', v)"
				></mw-select>
			</template>
		</mw-edit>
		<secondary-navigation v-if="id" show-back>
			<template v-slot:main>
				<nav-item v-if="canSchedule"
					@click="goTo('ProjectSlots')"
					text="Blocker"
					icon="mdi-calendar-clock"
				></nav-item>				
				<nav-item
					@click="goTo('ProjectEmailFields')"
					text="Merge Fields"
					icon="mdi-email"
				></nav-item>
				<nav-item
					@click="goTo('ProjectCandidates')"
					text="Candidates"
					icon="mdi-account-group-outline"
				></nav-item>
				<nav-item v-if="canSchedule"
					@click="goTo('ProjectSchedule')"
					text="Schedule"
					icon="mdi-calendar-month"
				></nav-item>
				<nav-item v-if="!project.incentive_only"
					@click="goTo('ProjectAppointments')"
					text="Sessions"
					icon="mdi-calendar"
				></nav-item>
				<nav-item
					@click="goTo('ExcludedParticipants')"
					text="Excluded Participants"
					icon="mdi-account-off"
				></nav-item>
				<nav-item
					@click="goTo('ProjectDocuments')"
					text="Documents"
					icon="mdi-file"
				></nav-item>

				<nav-item
					@click="goTo('ProjectRecruitingTile')"
					text="Currently Recruiting"
					icon="mdi-star"
				></nav-item>
				<nav-item v-if="project.incentive_only"
					@click="goTo('IncentiveOnlyProjectIncentives')"
					text="Incentive Submissions"
					icon="mdi-currency-gbp"
				></nav-item>
			</template>	
			<template v-slot:append>
				<edit-project-notes v-model="id"></edit-project-notes>
				<div class="ma-2">
					<track-button :id="id" object="project"></track-button>
				</div>
			</template>
		</secondary-navigation>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Project from "@/models/Project";
import MwEdit from "@c/ui/MwEdit";
import MwSelectUsers from "@c/ui/MwSelectUsers";
import MwSelect from "@c/ui/MwSelect";
import MwDatePicker from "@c/ui/MwDatePicker";
import SecondaryNavigation from "@c/navigation/Secondary";
import TrackButton from "@c/buttons/TrackButton";
import NavItem from "@c/navigation/Item";
import EditProjectNotes from "@c/edits/EditProjectNotes";
export default {
	name: "EditProject",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	provide() {
		return {
			projectId: this.id,
		};
	},
	computed: {
		model() {
			return Project;
		},
		consents(){
			let consents = this.$store.getters.getByKey("surveys", "type", "consent-form" );
			return consents.map( c => c.id )
		},
		screeners(){
			let screeners = this.$store.getters.getByKey("surveys", "type", "screener" );
			return screeners.map( c => c.id )
		},
		project(){
			if( !this.id ){
				return null;
			}
			return this.$store.state.projects.data[this.id];
		}, 
		canSchedule(){
			if( !this.project) {
				return false;
			}
			if( this.project.incentive_only ){
				return false;
			}
			return this.project.include_scheduling;
		}
	},
	methods: {
		update(key, value) {
			this.$store.dispatch("projects/patch", {
				id: this.id,
				[key]: value,
			});
		},
		goTo(name) {
			this.$router.push({ name: name, params: { id: this.id } });
		},
	},
	components: {
		MwEdit,
		EditProjectNotes,
		MwSelect,
		MwSelectUsers,
		TrackButton,
		SecondaryNavigation,
		NavItem,
		MwDatePicker,
	},
};
</script>
