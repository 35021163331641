export default {
	internal_name: {
		value: "internal_name",
		display: true,
		component: "v-text-field",
		label: "Internal Name",
	},
	client_name: {
		value: "client_name",
        copyFrom: "internal_name",
		display: true,
		component: "v-text-field",
		label: "Client Name",
	},
	public_name: {
		value: "public_name",
		display: true,
        copyFrom: "internal_name",
		component: "v-text-field",
		label: "Participant Name",
	},
	project_status: {
		value: "project_status",
		display: true,
		component: "v-select",
		items: ["prepping", "active", "postponed", "cancelled", "complete"],
		label: "Project Status",
		default: "prepping",
	},
    start: {
        value: "start", 
        display: true,
		require_id: true
    },
    end: {
        value: "end", 
        display: false,
		require_id: true
    },	
	display_to_client: {
		value: "display_to_client",
		display: true,
		default: false,
		label: "Display Project For Clients",
		component: "v-checkbox",
	},
	links_are_live: {
		value: "links_are_live",
		display: true,
		default: false,
		label: "Links to participate are clickable",
		component: "v-checkbox",
	},
	description: {
		value: "description",
		display: true,
		component: "v-textarea",
		label: "Project description",
	},
	info_for_participants: {
		value: "info_for_participants",
		display: true,
		component: "v-textarea",
		label: "Information for logged in participants",
	},
	hide_contact_details: {
		value: "hide_contact_details",
		display: true,
		default: true,
		label: "Hide Contact Details For Clients",
		component: "v-checkbox",
		require_id: true
	},
	include_scheduling: {
		value: "include_scheduling",
		display: true,
		default: true,
		label: "Include Scheduling",
		component: "v-checkbox",
		require_id: true
	},
	incentive_only: {
		value: "incentive_only",
		display: true,
		default: true,
		label: "Incentive Only Project",
		component: "v-checkbox",
		require_id: true
	},
	organisation: {
		value: "organisation",
		default: [],
		display: true,
		multiple: false,
		label: "Organisation",
		component: "MwSelect", 
		options: {
			storeModule: "organisations",
			newPath: "NewOrganisation",			
			itemText: "name",
			addNewButton: true

		},
		require_id: true
	},
	confirmation_email: {
		value: "confirmation_email",
		default: [],
		display: true,
		multiple: false,
		label: "Confirmation Email",
		component: "MwSelect", 
		options: {
			storeModule: "templates",
			newPath: "NewTemplate",			
			itemText: "id",
			addNewButton: true

		},
		require_id: true
	},
	reminder_email: {
		value: "reminder_email",
		default: [],
		display: true,
		multiple: false,
		label: "Reminder Email",
		component: "MwSelect", 
		options: {
			storeModule: "templates",
			newPath: "NewTemplate",			
			itemText: "id",
			addNewButton: true

		},
		require_id: true
	},
	clients: {
		value: "clients",
		default: [],
		display: true,
		label: "Clients",
		require_id: true,
		options: {
			addNewButton: true
		}
	},
	bulletin_recipients: {
		value: "bulletin_recipients",
		display: true
	},
	project_managers: {
		value: "project_managers",
		default: [],
		display: true,
		label: "Project Managers",
		require_id: true,
		options: {
			addNewButton: true
		}
	},
	exclude_participants: {
		value: "exclude_participants",
		default: [],
		display: false,
	},
	screeners: {
		value: "screeners",
		default: [],
		display: true,
		multiple: true,
		label: "Screeners",
		component: "MwSelect", 
		options: {
			itemText: "internal_name",
			storeModule: "surveys",
			newPath: "NewSurvey",
			addNewButton: true
		}, 
		require_id: true,
	},
	consent: {
		value: "consent",
		default: [],
		display: true,
		multiple: false,
		label: "Consent",
		component: "MwSelect", 
		options: {
			itemText: "internal_name",
			storeModule: "surveys",
			newPath: "NewSurvey",
			addNewButton: true
		},
		require_id: true
	},
	custom_fields: {
		value: "custom_fields",
		default: [],
		display: true,
		component: "v-combobox",
		options: {
			chips: true,
		},
		multiple: true,
		label: "Custom Fields",
		require_id: true
	},
	appointment_statuses: {
		value: "appointment_statuses",
		display: true,
		component: "v-combobox",
		options: {
			chips: true,
		},
		multiple: true,
		items: ["new", "confirmed", "complete", "cancelled", "no-show", "contacted", "set-up-done"],
		default: ["new", "confirmed", "complete", "cancelled", "no-show", "contacted", "set-up-done"],
		label: "Statuses",
		require_id: true
	},
	quotas: {
		value: "quotas",
		display: true,
		component: "v-combobox",
		options: {
			chips: true,
		},
		multiple: true,
		items: [],
		label: "Quotas",
		require_id: true
	},
	methodologies: {
		value: "methodologies",
		display: true,
		component: "v-combobox",
		options: {
			chips: true,
		},
		multiple: true,
		items: ["video", "f2f", "phone", "unattended"],
		label: "Methodologies",
	},
	slots: {
		value: "slots",
		default: [],
	},
	topic: {
		value: "topic",
		label: "Topic",
		component: "v-text-field",
		display: false,
	},
	internal_notes: {
		value: "internal_notes",
		display: false,
	},
	other_info: {
		value: "other_info",
		label: "Other Info",
		component: "v-textarea",
		display: false,
	},
	incentive: {
		value: "incentive",
		label: "Incentive Amount",
		component: "v-text-field",
		options: {type: "number"},
		display: false,
	}
};
